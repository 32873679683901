// @file creates URL object

import { currentCountryIso31662 } from '@@/bits/current_locale'
import environment from '@@/bits/environment'
import { HAS_URL_REGEX, WEB_URL_REGEX } from '@@/bits/regex'

const isUrl = (str: string | null): boolean => {
  if (str === null) return false
  return WEB_URL_REGEX.test(str)
}

const hasUrl = (str: string): boolean => {
  return HAS_URL_REGEX.test(str)
}

const addHttpsIfAbsent = (maybeUrl: string): string => {
  try {
    const url = new URL(maybeUrl)
    return url.toString()
  } catch {
    // Invalid URL but it could still be a URL
    // -> conveniently add https:// since user might not type it.
    const newUrl = `https://${maybeUrl}`
    return isUrl(newUrl) ? newUrl : maybeUrl
  }
}

const PROD_HOSTS = {
  native: 'padlet.com',
  org: 'padlet.org',
}
const STAGING_HOSTS = {
  native: 'padlet.io',
  org: 'padlet.org',
}
const DEV_HOSTS = {
  native: 'padlet.dev',
  org: 'padletorg.dev',
}
const TEST_HOSTS = {
  native: 'padlet.test',
  org: 'padletorg.test',
}
const PADLET_HOSTS = {
  production: PROD_HOSTS,
  staging: STAGING_HOSTS,
  development: DEV_HOSTS,
  test: TEST_HOSTS,
  e2e_test: TEST_HOSTS,
  e2e_ci: TEST_HOSTS,
}

const NATIVE_HOST: string = PADLET_HOSTS[environment].native
const ORG_HOST: string = PADLET_HOSTS[environment].org

function internalUrlRegexString(): string {
  return (
    '^' +
    // Allow protocol to be omitted
    '(https:\\/\\/)?' +
    // Allow subdomain to be omitted
    `([a-z0-9\\-]+\\.)?` +
    // Allow `padlet` and `padletorg` domain names
    `(padlet|padletorg)\\.` +
    // Allow `com`, `org`, `io`, `test` and `dev` TLDs
    `(com|org|io|test|dev)`
  )
}

function profileUrlRegexString(): string {
  return (
    internalUrlRegexString() +
    // Profile name
    '\\/[\\w_]{1,64}' +
    // Optional trailing slash
    '[\\/]?$'
  )
}

function wallUrlRegexString(): string {
  return (
    internalUrlRegexString() +
    // Namespace
    '\\/[a-zA-Z0-9][a-zA-Z0-9_]{1,64}' +
    // Wall name
    '\\/[a-zA-Z0-9_\\-]+' +
    // Optional trailing slash
    '[\\/]?$'
  )
}

function wallWishUrlRegexString(): string {
  return (
    internalUrlRegexString() +
    // Namespace
    '\\/[a-zA-Z0-9][a-zA-Z0-9_]{1,64}' +
    // Wall name
    '\\/[a-zA-Z0-9_\\-]+' +
    // Wish subpath
    '\\/wish' +
    // Wish hashid
    '\\/[\\w]+' +
    // Optional trailing slash
    '[\\/]?$'
  )
}

function sectionBreakoutRegexString(): string {
  return (
    internalUrlRegexString() +
    // Namespace
    '\\/[a-zA-Z0-9][a-zA-Z0-9_]{1,64}' +
    // breakout-link
    '\\/breakout-link' +
    // sectionHash-shareLinkHash
    '\\/[a-zA-Z0-9]+-[a-zA-Z0-9]+' +
    // Optional wish subpath
    '(\\/wish\\/[\\w]+)?' +
    // Optional trailing slash
    '[\\/]?$'
  )
}

function submissionRequestRegexString(): string {
  return (
    internalUrlRegexString() +
    // Namespace
    '\\/[a-zA-Z0-9][a-zA-Z0-9_]{1,64}' +
    // submission-request
    '\\/submission-request' +
    // shareLinkHash
    '\\/[a-zA-Z0-9]+' +
    // Optional trailing slash
    '[\\/]?$'
  )
}

function slideshowUrlRegexString(): string {
  return (
    internalUrlRegexString() +
    // Namespace
    '\\/[a-zA-Z0-9][a-zA-Z0-9_]{1,64}' +
    // Wall name
    '\\/[a-zA-Z0-9_\\-]+' +
    // Slideshow subpath
    '\\/slideshow' +
    // Optional: either wish subpath or section subpath
    '(\\/wish\\/[\\w]+|\\/section\\/[\\w]+)?' +
    // Optional trailing slash
    '[\\/]?$'
  )
}

function isValidWallUrlFormat(url): boolean {
  return !(RegExp(wallUrlRegexString()).exec(url) == null)
}

function isWallWishUrlFormat(url): boolean {
  return !(RegExp(wallWishUrlRegexString()).exec(url) == null)
}

function isWallOrWallWishUrlFormat(url): boolean {
  return isValidWallUrlFormat(url) || isWallWishUrlFormat(url)
}

function isProfileUrlFormat(url: string): boolean {
  return !(RegExp(profileUrlRegexString()).exec(url) == null)
}

function isSlideshowUrlFormat(url: string): boolean {
  return !(RegExp(slideshowUrlRegexString()).exec(url) == null)
}

function isSubmissionRequestUrlFormat(url: string): boolean {
  return !(RegExp(submissionRequestRegexString()).exec(url) == null)
}

function isBreakoutLinkUrlFormat(url: string): boolean {
  return !(RegExp(sectionBreakoutRegexString()).exec(url) == null)
}

function isInternalUrl(url: string): boolean {
  return !(RegExp(internalUrlRegexString()).exec(url) == null)
}

const getHostname = (str: string): string => {
  const url: URL = new URL(str)
  return url.hostname
}

const getPathWithLeadingSlash = (str: string): string => {
  let pathWithLeadingSlash = ''
  if (str.startsWith('/')) {
    pathWithLeadingSlash = str
  } else if (str.match(/^https?:/) !== null) {
    try {
      const url: URL = new URL(str)
      pathWithLeadingSlash = url.pathname + url.search + url.hash
    } catch (error) {
      console.warn('Not a valid URL', str)
      pathWithLeadingSlash = str.replace(/^https?:/, '')
    }
  } else {
    pathWithLeadingSlash = '/' + str
  }
  return pathWithLeadingSlash
}

const addQueryParams = (url: string | null, paramsString: string): string => {
  if (!url) return '?'.concat(paramsString)
  if (url.match(/\?/) != null) {
    return [url, paramsString].join('&')
  }
  return [url, paramsString].join('?')
}

const selfHarmHelplineUrl = (): string => {
  // Have to check for "XX" because that's what the backend returns for unknown country
  return `https://padlet.findahelpline.com/${currentCountryIso31662 === 'XX' ? '' : currentCountryIso31662}`
}

const getDemoUrl = (
  variant: 'external' | 'default' = 'default',
  config: { pageName?: string; linkLocation?: string },
): string => {
  const { pageName, linkLocation } = config
  const utmMedium = 'cta'
  const utmSource = pageName != null && pageName.length > 0 ? `padlet_${pageName}` : 'padlet'
  const utmCampaign = linkLocation != null && linkLocation.length > 0 ? `padlet_${linkLocation}` : 'padlet'
  // We use a redirect rules on Cloudflare to redirect to the correct URL depends on the variant to try out different ways to improve conversion rate
  // https://dash.cloudflare.com/cc33bc6b9fa2dcffb7db4d7877260139/padlet.com/rules/redirect-rules/faf7d000f94a46f584fab79ff9fe3599
  return `https://padlet.com/premium/backpack/try?variant=${variant}&utm_medium=${utmMedium}&utm_source=${utmSource}&utm_campaign=${utmCampaign}`
}

function goTo(url: string): void {
  location.replace(url)
}

const isValidUrl = (url: string): boolean => {
  try {
    return Boolean(new URL(url))
  } catch {
    return false
  }
}

/**
 * Checks if the given string is a valid URL and decodes it if
 * it's URL encoded.
 * @param url The URL to check.
 * @returns true if the URL is valid and safe, false otherwise.
 */
const validateExternalUrl = (url: string): boolean => {
  if (url === '') {
    return true
  }
  // Left-trim the url to avoid spaces
  url = url.replace(/^\s+/, '')
  // Ensure not percent encoded URL
  url = /^https?%3A/.test(url) ? decodeURIComponent(url) : url
  if (!/^https?/.test(url) || !isValidUrl(url)) {
    return false
  }
  return false
}

export {
  addHttpsIfAbsent,
  addQueryParams,
  getDemoUrl,
  getHostname,
  getPathWithLeadingSlash,
  goTo,
  hasUrl,
  isBreakoutLinkUrlFormat,
  isInternalUrl,
  isProfileUrlFormat,
  isSlideshowUrlFormat,
  isSubmissionRequestUrlFormat,
  isUrl,
  isValidWallUrlFormat,
  isWallOrWallWishUrlFormat,
  isWallWishUrlFormat,
  NATIVE_HOST,
  ORG_HOST,
  selfHarmHelplineUrl,
  validateExternalUrl,
}
